<template>
    <v-container fill-height fluid class="black d-flex align-stretch justify-center">
        <v-row class=""  justify="center" no-gutters>
            <v-col cols="12" md="11" class="py-0 px-0" >
                <menus/>
                <v-row class="fondoMobile">
                <v-col cols="0" md="2" class=" fondoi d-none d-md-flex">
                </v-col>
                <v-col cols="12" md="8" class="">
                <v-responsive max-width="1000" class="mx-auto text-center" :aspect-ratio="16/10">
                    <h1 class="font-weight-black mb-5 white--text">
                        SOUNDS LIKE YOU
                    </h1>
                    <h5 class="white--text mb-15">
                        <!-- CONTESTA ESTE QUIZ Y LL&Eacute;VATE UN VALE 
                        <v-spacer/>
                        POR UNA BECK's GR&Aacute;TIS EL D&Iacute;A DE TU EVENTO. -->
                        ARMAMOS UNA PLAYLIST PERFECTA PARA TÍ
                    </h5>
                    <!--EMPIEZA LA PRIMERA PREGUNTA-->
                    <h3 class="mb-15 white--text">
                        <!-- ¿QU&Eacute; G&Eacute;NERO MUSICAL TE REPRESENTA M&Aacute;S? -->
                        ¿C&Uacute;AL ES TU ESTILO?
                    </h3>
                    <v-spacer/>
                    <v-row no-gutters class="d-flex justify-center">
                        <v-col cols="4" md="2">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/4kQvgWBWnaAedBM6cQW33y?si=232f3a6b7e0047ff"
                                target="_blank"
                            >
                                GAMER
                            </v-btn>
                        </v-col>
                        <v-col class="d-none" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                            />
                        </v-col>
                        <v-col cols="8" md="3">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/1LcRFZYZnxOq68chOozIbg?si=aa149fec728a4ce5"
                                target="_blank"
                            >
                                TRIPPY HYPEBEAST
                            </v-btn>
                        </v-col>
                        <v-col class="d-none" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                            />
                        </v-col>
                        <v-col cols="4" md="2">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/4vu9HLSvjJI02ZPl1gh4XO?si=76793524add94925"
                                target="_blank"
                            >
                                LO-FI
                            </v-btn>
                        </v-col>
                        <v-col class="d-none" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                            />
                        </v-col>
                        <v-col cols="6" md="2">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/6CqDRxSZUOfqs3iaNHBgO0?si=fad9648f997e4e82"
                                target="_blank"
                            >
                                PUNK ROCK
                            </v-btn>
                        </v-col>
                        <v-col class="d-none" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                            />
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/3QNTyjhdBhO6evitVVwtCA?si=49be6b5fe10544c5"
                                target="_blank"
                            >
                                80'S MIXTAPE
                            </v-btn>
                        </v-col>
                        <v-col cols="5" md="2" class="mt-3">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/5m3m48Mx5cqdUUBwRfjNIw?si=7075521e62834f84"
                                target="_blank"
                            >
                                FUTURIST
                            </v-btn>
                        </v-col>
                        <v-col class="d-none" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                disabled
                            />
                        </v-col>
                        <v-col cols="3" md="2" class="mt-3">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/4Gc7YASsb8SFW2zjrskYkO?si=841b45b3a4594833"
                                target="_blank"
                            >
                                PRIDE
                            </v-btn>
                        </v-col>
                        <v-col class="d-none" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                disabled
                            />
                        </v-col>
                        <v-col cols="4" md="2" class="mt-3">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/2vrPhcEeJpReuzU791Yy2x?si=ed5578a39ef647b5"
                                target="_blank"
                            >
                                FITNESS
                            </v-btn>
                        </v-col>
                        <v-col class="d-md-none d-none">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                disabled
                            />
                        </v-col>
                        <v-col cols="6" md="3" class="mt-3">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/6oU6ZlHC0sUu6LkmvVSbI1?si=b20104ae4ae64d74"
                                target="_blank"
                            >
                                NO PLANET B
                            </v-btn>
                        </v-col>
                        <v-col class="d-none" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                disabled
                            />
                        </v-col>
                        <v-col cols="6" md="2" class="mt-3">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/21yzOVswvK7Y8awCI6oo7N?si=b6d8564db8b442bc"
                                target="_blank"
                            >
                                SPACECORE
                            </v-btn>
                        </v-col>
                        <v-col cols="5" md="2" class="mt-3">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/5OnVIgDaZaxJocwPqo0WlM?si=0d591b0bbf454604"
                                target="_blank"
                            >
                                PINK POP
                            </v-btn>
                        </v-col>
                        <v-col class="d-none" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                disabled
                            />
                        </v-col>
                        <v-col cols="6" md="3" class="mt-3">
                            <v-btn
                                dark
                                class="my-2 pruebas overflow-hidden"
                                elevation="2"
                                outlined
                                rounded
                                block
                                href="https://open.spotify.com/playlist/7vCJWcrqfHxugk9C4E0LCI?si=99b3790948cf453b"
                                target="_blank"
                            >
                                COTTAGECORE
                            </v-btn>
                        </v-col>
                    </v-row>
                    <!--Aqui empieza la segunda pregunta-->
                    <!-- <h3 class="mt-10 mb-5 white--text">
                        ¿QU&Eacute; DEPORTE O ACTIVIDAD F&Iacute;SICA DISFRUTAS M&Aacute;S?
                    </h3>
                    <v-spacer/>
                    <v-row no-gutters>
                        <v-col cols="10" md="3">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                            >
                                NATACI&Oacute;N
                            </v-btn>
                        </v-col>
                        <v-col cols="2" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                                disabled
                            />
                        </v-col>
                        <v-col cols="6" md="2">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                            >
                                GYM
                            </v-btn>
                        </v-col>
                        <v-col cols="6" md="2">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                            >
                                TENIS
                            </v-btn>
                        </v-col>
                        <v-col cols="10" md="3">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                            >
                                FOOTBALL
                            </v-btn>
                        </v-col>
                        <v-col cols="2" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                                disabled
                            />
                        </v-col>
                        <v-col cols="2" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                                disabled
                            />
                        </v-col>
                        <v-col cols="8" md="2">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                            >
                                CORRER
                            </v-btn>
                        </v-col>
                        <v-col cols="2" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                                disabled
                            />
                        </v-col>
                        <v-col cols="2" md="1">
                        <v-btn
                            dark
                            class="my-2 pruebas"
                            elevation="2"
                            outlined
                            rounded
                            block
                            disabled
                        />
                        </v-col>
                        <v-col cols="7" md="3">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                            >
                                BICICLETA
                            </v-btn>
                        </v-col>
                        <v-col cols="3" class="d-md-none">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                                disabled
                            />
                        </v-col>
                        <v-col cols="5" md="1">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                                disabled
                            />
                        </v-col>
                        <v-col cols="7" md="3">
                            <v-btn
                                dark
                                class="my-2 pruebas"
                                elevation="2"
                                outlined
                                rounded
                                block
                            >
                                BASKETBALL
                            </v-btn>
                        </v-col>
                    </v-row> -->
                    <v-spacer/>
                    <!-- <v-btn class="becks d-none d-md-inline my-15" rounded large @click="controler = false; dialog= !dialog; saveEncuesta()">
                        &nbsp;&nbsp;GUARDAR&nbsp;&nbsp;
                    </v-btn>
                    <v-btn class="becks d-md-none my-15" rounded large @click="controler = true; dialog= !dialog; saveEncuesta()">
                        &nbsp;&nbsp;GUARDAR&nbsp;&nbsp;
                    </v-btn> -->
                </v-responsive>
                </v-col>
                <v-col cols="0" md="2" class="fondod d-none d-md-flex">

                </v-col>
                </v-row>
                <v-dialog
                v-model="dialog"
                max-width="450"
                :fullscreen="controler"
                class="align-center d-flex"
                align="center"
                >
                <v-card 
                    class="becks px-5 pt-5 pb-10 black--text align-center"
                    :rounded="controler?'0':'xl'"
                >
                <v-card-title>
                        <v-spacer/>
                        <v-btn
                                icon
                                color="black"
                                class="mr-n3 mb-n5"
                                style="z-index:3"
                                @click="dialog = !dialog"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                    </v-card-title> 
                <v-responsive class="align-center mt-n10" :aspect-ratio="controler?9/16:1">
                    
                    <div class="fllave d-flex align-center justify-center">
                            <h1>¡GRACIAS!</h1>
                        </div>
                        <br>
                    <v-card-subtitle class="text-center justify-center black--text " style="font-size: 80%">
                        CREAMOS UNA PLAYLIST S&Oacute;LO PARA TI
                    </v-card-subtitle>
                    <v-btn class="my-5 black--text" rounded block outlined>
                        <v-icon>mdi-spotify</v-icon>LL&Eacute;VAME A ELLA
                    </v-btn>
                    <v-btn class="my-5" rounded block text @click="cambiarP('AgregarUnaFoto')">
                        REGRESAR AL INICIO
                    </v-btn>
                </v-responsive>
                </v-card>
                </v-dialog>
            </v-col>        
        </v-row>
    </v-container>

</template>
<script>

    import menus from '../components/menus.vue'
    import axios from 'axios'

    export default {
        name: 'Encuesta',
        components: {
            menus
        },
        data: () => ({
            controler: false,
            dialog: false,
            music: 'jazz',
            sport: 'correr'
        }),
        methods: {
            cambiarP(lugar){
                axios.get('/').then(response => (
                    this.$router.push({name: lugar})
                ))
                .catch(
                    error => console.log('Hubo un error: ' + error)
                )
            },
            saveEncuesta(){

                axios.post("/save_encuesta.php", {

                        musica: this.music,
                        deporte: this.sport

                    }).then(function(response) {

                        // console.log(response)

                    }).catch(error => {

                        // console.log(error)

                    })
            },
            checkCookie(){
              axios
                  .post("check_cookie.php", {
                  
                  })
                  .then(function (response) {
                    // console.log(response.data.message)

                    if(response.data.message != 'galleta'){
                      window.location.replace("/Edad");
                    }

                  })
                  .catch((error) => {
                    //   console.log(error)
                  });
            }
        },
        created(){
            this.checkCookie();
        }
    }
</script>
<style scoped>
    .fllave{
        background-image: url('../assets/llave.png');
        background-position-x: center;
        background-size: contain;
        min-height: 150px;
    }
    .pruebas{
        border: white solid 1px ;
        background-color: black;
    }
@media (max-width: 960px){
        .fondo{
            background-image: url(../assets/ManifiestoIzquierdaFinal.png);
            background-size: contain;
            background-position-y: top;
            background-repeat: no-repeat;
            
        }
        .fondo2{
            background-image: url(../assets/encuestaMobile2.png);
            background-size: contain;
            background-position-x: right;
            background-position-y: bottom;
            background-repeat: no-repeat;
        }
        .fondoMobile{
            background-image: url(../assets/manifiestoMobileFinal.jpg);
            background-size: cover;
            background-position-y: top;
            background-repeat: no-repeat;
        }
    }
    
    .fondod{
        background-image: url(../assets/ManifiestoDerecha.png);
        z-index: 10;
        background-size: contain;
        background-position-x:right;
        background-repeat: no-repeat;
        
    }
    .fondoi{
        background-image: url(../assets/ManifiestoIzquierdaFinal.png);
        background-size: contain;
        background-position-x: left;
        background-repeat: no-repeat;
        
    }
</style>
